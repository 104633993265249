import { head, get, isEmpty, compact } from 'lodash';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';
import { OG_TYPE_PRODUCT } from 'site-modules/shared/constants/og-types';
import { getCarVinSchema } from 'client/seo/schemas/car';
import { getVehicleVinSchema } from 'client/seo/schemas/vehicle';
import { getProductSchema } from 'client/seo/schemas/product';
import { getFAQSchema } from 'client/seo/schemas/faq';
import { getModelState } from 'client/data/luckdragon/model';
import { UrlModel, URL_CONTEXT_PATH } from 'client/data/models/url';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { getSeoImage } from 'site-modules/shared/utils/inventory-utils/get-seo-image';
import { getNonAmpUrl } from 'site-modules/shared/utils/url-utils';
import { processAmpCanonicals, generateCanonical, generateRelLink } from 'site-modules/shared/pages/seo';
import { buildCoreBreadcrumbs } from 'site-modules/shared/utils/core-breadcrumbs-builder';
import { buildVehiclePath, VehicleModel } from 'client/data/models/vehicle';
import { InventoryModel } from 'client/data/models/inventory';
import { VisitorModel } from 'client/data/models/visitor';
import { isCheapLeaseSRP } from 'site-modules/shared/utils/inventory/srp-type-checkers';
import { getPrimaryImageOfPage } from 'client/site-modules/shared/utils/inventory/srp-images';
import { getWebpageSchema } from 'client/seo/schemas/webpage';
import {
  buildSearchFilterSEOObject,
  getPaginationUrls,
  getRobots,
  generateSEOTitle,
  generateSEODescription,
  generateSEOKeywords,
  generateSEOBreadcrumbs,
} from 'site-modules/shared/utils/inventory/srp-seo-utils';
import { getMakeTypeBreadcrumbs } from 'site-modules/shared/components/breadcrumbs/srp-helper';
import { getUrlPattern } from 'site-modules/shared/utils/inventory/srp-utils';
import { appendReviewSchema } from 'client/seo/schemas/review';
import { buildAuthorPath, buildReviewPath, EditorialReviewModel } from 'client/data/models/editorial-review';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { isUsedSRPReviewComboPage } from 'site-modules/shared/utils/inventory/srp-review-combo';

const IMAGE_DATA_PATH = 'vehicleInfo.photo.defaultPhoto.largePhotoCount';
export const SEOT4148PATH = '/used-toyota-land-cruiser-ontario-ca/';
function transformConsumerReviews(consumerReviews) {
  return {
    reviews: get(consumerReviews, 'reviews', []).map(review => ({
      ...review,
      author: {
        authorName: review.userName,
      },
      created: review.createDate,
    })),
    ratingAggregation: {
      averageStars: get(consumerReviews, 'averageConsumerRatings', 0),
    },
    totalReviews: get(consumerReviews, 'totalReviewCount', 0),
  };
}

/**
 * Get seo object
 * @param state {object}
 * @param props {object}
 * @returns {object}
 */
export function getSrpSeoObject(state, props) {
  const modelState = getModelState(state);
  const disableInventoryMLPhotoSort = !!get(state, 'featureFlags["disable-inventory-ml-photo-sort"]');
  const params = get(props, 'match.params', {});
  const pathName = props.location.pathname;
  const urlContext = modelState.get(URL_CONTEXT_PATH, UrlModel) || {};
  const isSrpOnUsedCore = get(urlContext, 'isSrpOnUsedCore', false);
  const isSrpOnMakeType = get(urlContext, 'isSrpOnMakeType', false);
  const visitorLocation = modelState.get('location', VisitorModel);
  const searchResultsFilter = modelState.get('searchResultsFilter', InventoryModel);
  const pageData = modelState.get('searchResults', InventoryModel);
  const totalNumber = get(pageData, 'inventories.totalNumber', 0);
  const seoInfo = get(pageData, 'seoInfo', {});
  const results = get(pageData, 'inventories.results', []);
  const attributes = get(pageData, 'attributes', {});
  const reviewsData = get(seoInfo, 'consumerReviews', {});
  const vehicle = get(urlContext, 'vehicle', {});
  const urlPattern = getUrlPattern(urlContext);
  const baseInventory = !isEmpty(results) ? head(results) : {};
  const stockPhotos = [];
  const image = getSeoImage(baseInventory, stockPhotos, IMAGE_DATA_PATH);
  const pageNumber = get(pageData, 'inventories.pageNumber', 1);
  const pagesCount = get(pageData, 'inventories.totalPages');
  const seoData = buildSearchFilterSEOObject({
    searchResultsFilter,
    urlContext,
    inventories: results,
    attributes,
    inventoryCount: totalNumber,
    pageNumber,
  });
  const { make, model, year, submodel } = seoData;
  const vehicleName = compact([year, make, model, submodel]).join(' ');
  const isLeaseSrp = seoData.isLease;
  const paginationUrls = isSrpOnUsedCore
    ? getPaginationUrls({
        pageNumber,
        pagesCount,
        pathName,
      })
    : getPaginationUrls({
        pageNumber,
        pagesCount,
        selectedFacets: searchResultsFilter,
        urlContext,
        pathName,
      });
  const makeModelSubmodelYear = modelState.get(buildVehiclePath(params), VehicleModel);
  const makeSlug = get(vehicle, 'make.slug');
  const redirectUrl = get(urlContext, 'redirectUrl', '');
  const redirectUrlPathnameObj = redirectUrl ? { pathname: redirectUrl } : null;
  let canonical = processAmpCanonicals(generateCanonical(redirectUrlPathnameObj || props.location));
  const enableSellerSchema = !!get(state, 'featureFlags["enable-seller-schema"]');

  const hasUsedCoreContent = isUsedSRPReviewComboPage(urlContext, searchResultsFilter);

  if (pathName === '/car-lease-deals/') {
    canonical = 'https://www.edmunds.com/lease-deals/';
  }

  if (isCheapLeaseSRP(urlPattern)) {
    canonical = 'https://www.edmunds.com/cheap-lease-deals/';
  }

  const title = generateSEOTitle({
    seoData,
    visitorLocation,
    isMeta: true,
    isUsedCoreComboTitle: hasUsedCoreContent,
  });
  const description = generateSEODescription(totalNumber, seoInfo, seoData, results);

  const addSellerAddresstoJsonLd = enableSellerSchema && ['new-make-model-for-sale-city-state'].includes(urlPattern);
  const isSeot4290 =
    !!get(state, 'featureFlags["enable-seot-4290-car-type-schema-2022"]') &&
    year === '2022' &&
    urlPattern === 'make/model/year';
  const { city, stateName } = addSellerAddresstoJsonLd ? get(urlContext, 'location', {}) : {};
  const seoObj = {
    title,
    description,
    keywords: generateSEOKeywords(urlContext, seoData),
    canonical,
    image,
    type: OG_TYPE_PRODUCT,
    jsonld: results.map(result =>
      isSrpOnUsedCore
        ? getVehicleVinSchema({ vehicle: result, disableInventoryMLPhotoSort, isSeot4290 })
        : getCarVinSchema({ vinData: result, disableInventoryMLPhotoSort, city, stateName })
    ),
    robots: getRobots(searchResultsFilter, urlContext, seoInfo),
    prev: generateRelLink(paginationUrls.prev),
    next: generateRelLink(paginationUrls.next),
  };

  const inventoryType = get(urlContext, 'attributes.inventory.inventoryType');
  const isNewType = inventoryType && isNew(inventoryType);
  const hasWebpageSchema = !isNewType && makeSlug && results.length;
  const webpageSchema = hasWebpageSchema
    ? {
        ...getWebpageSchema(title),
        primaryImageOfPage: {
          '@type': 'ImageObject',
          url: getPrimaryImageOfPage(results),
        },
      }
    : {};
  if (hasWebpageSchema) {
    seoObj.jsonld.push(webpageSchema);
  }

  const faqApiData = get(seoInfo, 'faqWidget');
  const hasFaqApiData = !!faqApiData && faqApiData.items.length > 0;
  if (hasFaqApiData) {
    const faqContent = compact(get(faqApiData, 'items'));
    seoObj.jsonld.push(
      getFAQSchema({
        entries: faqContent,
        generateLearnMoreLinks: true,
        withTracking: !isNewType,
        learnMoreLinkText: get(faqApiData, 'learnMoreText'),
        pathname: pathName,
      })
    );
  }

  if (isLeaseSrp) {
    const srpNarrativeText = get(seoInfo, 'narrativeWidget.items');
    const learnMoreLinkText = get(seoInfo, 'narrativeWidget.learnMoreText');

    if (srpNarrativeText) {
      seoObj.jsonld.push(
        getFAQSchema({
          entries: srpNarrativeText,
          questionLabel: 'heading',
          answerLabel: 'content',
          generateLearnMoreLinks: true,
          withTracking: true,
          learnMoreLinkText,
          pathname: pathName,
        })
      );
    }
  }

  if (isSrpOnUsedCore) {
    if (makeModelSubmodelYear) {
      seoObj.jsonld.push(
        getBreadcrumbSchema(
          buildCoreBreadcrumbs({
            makeModelSubmodelYear,
            params,
            pageDisplayName: '',
            includeLastLink: true,
          })
        )
      );
    }
    seoObj.keywords = `${vehicleName} review`;
  } else if (isSrpOnMakeType) {
    seoObj.jsonld.push(getBreadcrumbSchema(getMakeTypeBreadcrumbs(seoData)));
  } else {
    seoObj.jsonld.push(
      getBreadcrumbSchema(
        generateSEOBreadcrumbs({ originalUrl: getNonAmpUrl(pathName), seoData, includeLastLink: true })
      )
    );
  }

  seoObj.jsonld = compact(seoObj.jsonld);

  const vehicleType = get(attributes, 'type');
  const isSeot4148 = pathName === SEOT4148PATH && hasWebpageSchema;
  const productSchema = getProductSchema({
    searchResults: pageData,
    image,
    consumerReviews: transformConsumerReviews(reviewsData),
    description,
    vehicleType,
    make,
    model,
    year,
    isSeot4148,
  });

  if (!productSchema) {
    return seoObj;
  }

  if (isSeot4148) {
    webpageSchema.mainEntity = [];
  }
  const productSchemaLocation = isSeot4148 ? webpageSchema.mainEntity : seoObj.jsonld;

  if (hasUsedCoreContent) {
    const vehicleParams = getParamsFromVehicle(makeModelSubmodelYear);
    const editorialReview = vehicleParams && modelState.get(buildReviewPath(vehicleParams), EditorialReviewModel);
    const editorialReviewAuthor = vehicleParams && modelState.get(buildAuthorPath(vehicleParams), EditorialReviewModel);
    productSchemaLocation.push(
      appendReviewSchema(productSchema, {
        makeModelSubmodelYear,
        editorialReview,
        author: editorialReviewAuthor,
        hasItemReviewed: false,
        isCorePage: true,
      })
    );
  } else {
    productSchemaLocation.push(productSchema);
  }

  return seoObj;
}
