import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CAPITAL_ONE_ENTRY_POINT_SRP } from 'site-modules/shared/constants/financing/financing-constants';
import { CapitalOneEntryPointLogo } from 'site-modules/shared/components/financing/capital-one-entry-point/components/capital-one-entry-point-logo/capital-one-entry-point-logo';
import { Link } from 'site-modules/shared/components/link/link';
import { BenefitsWithIcons } from 'client/site-modules/shared/components/financing/components/benefits-with-icons/benefits-with-icons';
import { CapitalOneBenefitCard } from 'site-modules/shared/components/financing/capital-one-entry-point/components/capital-one-benefit-card/capital-one-benefit-card';

import './capital-one-entry-point-srp.scss';

export function CapitalOneEntryPointSrp({ isMobile, wrapperClass, ted6443Chal }) {
  const data = CAPITAL_ONE_ENTRY_POINT_SRP[ted6443Chal];
  const { header1, header2, subHeader, ctaText, ctaClasses, benefits, disclaimer } = data;
  const isTED6443Chal1 = ted6443Chal === 'chal-1';
  const ted6159Benefits = isTED6443Chal1 ? (
    <ul
      className=" flex-column flex-md-row justify-content-center list-unstyled"
      style={{ marginBottom: '24px', display: 'flex' }}
    >
      {benefits.map((benefit, index) => (
        <li key={`benefit_${index + 1}`}>
          <CapitalOneBenefitCard
            benefit={benefit}
            isMobile={isMobile}
            {...index === 1 && isMobile && { showDivider: true }}
            {...index === 0 && isMobile && { benefitCardWrapperClass: 'flex-row-reverse' }}
            benefitCardClass={benefit.benefitCardClass}
          />
        </li>
      ))}
      {isMobile && <div className="text-cool-gray-30 text-center mt-0_75">{disclaimer}</div>}
    </ul>
  ) : (
    <BenefitsWithIcons
      benefits={benefits}
      benefitItemClass="d-flex flex-column align-items-center"
      benefitCopyClass="text-left"
    />
  );

  return (
    <div
      data-tracking-parent="used-summary_financing"
      className={classnames('capital-one-entry-point-wrapper rounded-8', wrapperClass, {
        'bg-white with-border': !isTED6443Chal1,
        'with-bg': isTED6443Chal1,
      })}
      style={{ paddingTop: '36px', paddingBottom: '24px' }}
    >
      <div
        className={classnames({
          'd-flex flex-column align-items-center': isMobile && !isTED6443Chal1,
        })}
      >
        <div className="d-flex justify-content-center">
          {!isTED6443Chal1 && <CapitalOneEntryPointLogo width={103} height={37} />}
        </div>
        <h2
          className={classnames('text-center', {
            'size-30 mb-1': isTED6443Chal1,
            'size-24 mb-1_5': !isTED6443Chal1,
            'mobile-header': isMobile && !isTED6443Chal1,
          })}
        >
          {header1}
          <span className="text-green-20">{header2}</span>
        </h2>
        {subHeader && <p className="subheader text-center size-16 font-weight-normal mx-auto mb-2">{subHeader}</p>}

        <div className="capital-one-container">{ted6159Benefits}</div>

        <div className={classnames('d-flex justify-content-center', { 'w-100': isMobile && !isTED6443Chal1 })}>
          <Link
            to="/financing/prequalify-and-shop/basics/"
            className={classnames(ctaClasses, 'btn btn-sm text-transform-none size-16 font-weight-medium text-white', {
              'w-100': isMobile && !isTED6443Chal1,
            })}
            data-tracking-id="financing_cta"
            data-tracking-value={ctaText}
            target="_blank"
          >
            <span>{ctaText}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

CapitalOneEntryPointSrp.propTypes = {
  ted6443Chal: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

CapitalOneEntryPointSrp.defaultProps = {
  isMobile: false,
  wrapperClass: '',
};
