import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { CAPITAL_ONE_LOGO_SRC } from 'site-modules/shared/constants/financing/financing-constants';

export const CapitalOneEntryPointLogo = ({ classes, width, height }) => (
  <img
    width={width}
    height={height}
    src={getStaticImageUrl(CAPITAL_ONE_LOGO_SRC)}
    alt="Capital One"
    className={classnames('d-block mb-0_25', classes)}
  />
);

CapitalOneEntryPointLogo.propTypes = {
  classes: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CapitalOneEntryPointLogo.defaultProps = {
  classes: '',
  width: 73,
  height: 26,
};
