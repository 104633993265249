import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { formatPriceStringV2 } from 'site-modules/shared/utils/price-utils';

import './capital-one-benefit-card.scss';

export function CapitalOneBenefitCard({
  benefit,
  isMobile,
  priceValue,
  showDivider,
  hideSign,
  benefitCardClass,
  signClass,
  showPriceAsterisk,
  benefitCardWrapperClass,
}) {
  const { header, headerClass, description, imageSrc, imageWidth, sign } = benefit;
  return (
    <>
      <div
        className={classnames('d-flex align-self-end align-self-md-center', benefitCardWrapperClass, {
          'justify-content-between w-100': isMobile && !!sign,
        })}
      >
        {sign && !hideSign && (
          <div className="d-flex flex-column justify-content-center">
            <span className={classnames(signClass, 'sign')}>{sign}</span>
          </div>
        )}
        <div
          className={classnames(
            'benefit-card bg-white d-flex flex-md-column justify-content-between px-1 py-md-1 align-items-center',
            benefitCardClass,
            { 'my-1_5 my-md-0': !!sign }
          )}
        >
          <div className={classnames('text-cool-gray-30 text-center font-weight-bold px-md-0_5', headerClass)}>
            {header}
            {priceValue && (
              <div className="font-weight-bold price-value mt-0_25">
                {formatPriceStringV2(priceValue)}
                {showPriceAsterisk && '*'}
              </div>
            )}
          </div>

          <div className="d-flex justify-content-center ml-1 ml-md-0">
            <img src={getStaticImageUrl(imageSrc)} alt="" loading="lazy" width={imageWidth} />
          </div>

          {!isMobile && <div className="description text-cool-gray-30 text-center">{description}</div>}
        </div>
      </div>
      {showDivider && <div className="divider" />}
    </>
  );
}

CapitalOneBenefitCard.propTypes = {
  benefit: PropTypes.shape({}),
  isMobile: PropTypes.bool,
  priceValue: PropTypes.number,
  showPriceAsterisk: PropTypes.bool,
  showDivider: PropTypes.bool,
  hideSign: PropTypes.bool,
  benefitCardClass: PropTypes.string,
  benefitCardWrapperClass: PropTypes.string,
  signClass: PropTypes.string,
};

CapitalOneBenefitCard.defaultProps = {
  benefit: {},
  isMobile: false,
  priceValue: undefined,
  showPriceAsterisk: false,
  showDivider: false,
  hideSign: false,
  benefitCardClass: '',
  benefitCardWrapperClass: '',
  signClass: '',
};
